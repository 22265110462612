/* eslint-disable @typescript-eslint/no-unused-vars */
import {Alert, AlertColor, Snackbar} from '@mui/material';
import {createContext, useContext, useState, useMemo, ReactNode} from 'react';

type SnackBarType = {
  showSnackBar: (message: string, severity?: AlertColor) => void;
};

const snackBarContextDefaultValues: SnackBarType = {
  showSnackBar: () => {},
};

const SnackBarContext = createContext<SnackBarType>(snackBarContextDefaultValues);

export function useSnackBar() {
  return useContext(SnackBarContext);
}

interface ISnackBar {
  open: boolean;
  message: string;
  severity: AlertColor;
}

type Props = {
  children: ReactNode;
};

export function SnackBarProvider({children}: Props) {
  const [snackbarParam, setSnackbarParam] = useState<ISnackBar>({
    open: false,
    message: '',
    severity: 'success',
  });

  const showSnackBar = (message: string, severity?: AlertColor) => {
    setSnackbarParam({open: true, message, severity: severity || 'success'});
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setSnackbarParam({open: false, message: '', severity: 'success'});
  };

  const contextValue = useMemo(
    () => ({
      showSnackBar,
    }),
    [],
  );

  return (
    <SnackBarContext.Provider value={contextValue}>
      {children}
      <Snackbar
        open={snackbarParam.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      >
        <Alert
          onClose={handleClose}
          severity={snackbarParam.severity}
          variant="filled"
          sx={{width: '100%'}}
        >
          {snackbarParam.message}
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
}
