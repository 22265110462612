/* eslint-disable no-bitwise */
import {ICriteria} from 'src/components/web-reporting/maintenance';
import {MODE_DEV, MODE_DEV_API_URL} from 'src/config/mode-development';
import {TokenSession} from '../models/commons';
import {ICabinAppTheme} from '../models/user';
import {AISUserService} from './dataServices';
import {AvailableKpis} from '../constants';

const CSTE_LS_KEYS = {
  KEY_DISPATCHER: 'AIS-Dispatcher-Response',
  KEY_TOKEN: 'token',
  KEY_WR_MAINTENANCE_CRITERIA: 'AIS-WR-MAINTENANCE-CRITERIA',
  KEY_WR_DASHBOARD_PERIODE: 'AIS-WR-DASHBOARD_PERIODE',
  KEY_WR_MODULE_HREF: 'AIS-MODULE-HREF',
  KEY_WR_SUPERUSER: 'AIS-SU',
  KEY_WR_CABINAPP_THEME: 'AIS-CABINAPP_THEME',
  KEY_WR_THEME: 'AIS-appTheme',
  KEY_WR_MODULE_CURRENT: 'AIS-MODULE-CURRENT',
};

class AISCacheDataService {
  private setItem(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  removeItem(key: string) {
    window.localStorage.removeItem(key);
  }

  private getItem(key: string): string {
    return window.localStorage.getItem(key);
  }

  private getItemAsObject(key: string): any {
    const value = this.getItem(key);

    return value && value.length ? JSON.parse(value) : undefined;
  }

  private getCustomerIdFromUser(): number {
    const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);

    if (token && token.user && token.user.CustomerId) {
      return parseInt(token.user.CustomerId, 10);
    }
    return undefined;
  }

  setDispatcherResponse(value: any) {
    value.envVars = {ENV: value.envVars.ENV};
    this.setItem(CSTE_LS_KEYS.KEY_DISPATCHER, JSON.stringify(value));
  }

  getDispatcherResponse() {
    return this.getItemAsObject(CSTE_LS_KEYS.KEY_DISPATCHER);
  }

  setToken(token: TokenSession) {
    this.setItem(CSTE_LS_KEYS.KEY_TOKEN, JSON.stringify(token));
  }

  getToken(): TokenSession {
    const strToken = this.getItem(CSTE_LS_KEYS.KEY_TOKEN);

    return strToken ? JSON.parse(strToken) : undefined;
  }

  setTheme(value: any) {
    if (value && value.colors) {
      delete value.bg_image;
      this.setItem(CSTE_LS_KEYS.KEY_WR_THEME, 'AISCustomerTheme');
      this.setItem(CSTE_LS_KEYS.KEY_WR_CABINAPP_THEME, JSON.stringify(value));
    } else {
      this.setItem(CSTE_LS_KEYS.KEY_WR_THEME, 'AISDefaultTheme');
    }
  }

  getTheme() {
    return this.getItem(CSTE_LS_KEYS.KEY_WR_THEME);
  }

  getCabinAppTheme(): ICabinAppTheme {
    const cabinAppTheme = this.getItem(CSTE_LS_KEYS.KEY_WR_CABINAPP_THEME);

    return JSON.parse(cabinAppTheme);
  }

  removeDispatcherResponse() {
    this.removeItem(CSTE_LS_KEYS.KEY_DISPATCHER);
    this.removeItem(CSTE_LS_KEYS.KEY_TOKEN);
  }

  getApiValueFromDispatcher() {
    if (MODE_DEV) {
      return MODE_DEV_API_URL;
    }

    const dispatcherResponse = this.getDispatcherResponse();

    if (dispatcherResponse && dispatcherResponse.newapiurl) {
      return dispatcherResponse.newapiurl;
    }

    return undefined;
  }

  getSSOCustom(): any {
    const dispatcherResponse = this.getDispatcherResponse();

    if (dispatcherResponse && dispatcherResponse.login && dispatcherResponse.login.custom) {
      const custom = dispatcherResponse.login.custom;

      return custom[0];
    }
    return undefined;
  }

  getProfileOptions(): any {
    const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);

    if (token && token.configuration && token.configuration.selectProfileOptions) {
      return token.configuration.selectProfileOptions;
    }
    return [];
  }

  getCustomerIdFromDispatcher(): number {
    const dispatcherResponse = this.getDispatcherResponse();

    if (dispatcherResponse && dispatcherResponse.customerId) {
      return parseInt(dispatcherResponse.customerId, 10);
    }
    return undefined;
  }

  getCustomerId(): number {
    let customerId = this.getCustomerIdFromDispatcher();
    if (!customerId) {
      customerId = this.getCustomerIdFromUser();
      if (customerId) {
        this.setCustomerIdToDispatcherResponse(customerId);
      }
    }
    return customerId;
  }

  setCustomerIdToDispatcherResponse(customerId: number) {
    const dispatcherResponse = this.getDispatcherResponse();

    if (dispatcherResponse) {
      dispatcherResponse.customerId = customerId;
      this.setDispatcherResponse(dispatcherResponse);
    }
  }

  getEnvVarsValue(key: string) {
    const dispatcherResponse = this.getDispatcherResponse();

    if (dispatcherResponse && dispatcherResponse.envVars) {
      return dispatcherResponse.envVars[key]
        ? dispatcherResponse.envVars[key]
        : `Env variable ${key} unknown`;
    }
    return '';
  }

  getEnvironment(): string {
    const env: string = this.getEnvVarsValue('ENV');

    return env.charAt(0).toUpperCase() + env.slice(1).toLocaleLowerCase();
  }

  setPreviousEnvironment() {
    this.setItem('PREVIOUS-ENV', this.getEnvironment());
  }

  getPreviousEnvironment(): string {
    return this.getItem('PREVIOUS-ENV');
  }

  getBddNameValueFromConfiguration() {
    const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);

    if (token && token.configuration && token.configuration.db) {
      return token.configuration.db;
    }
    return undefined;
  }

  getGitCommitValueFromConfiguration() {
    const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);

    if (token && token.configuration && token.configuration.gitcommit) {
      return token.configuration.gitcommit;
    }
    return '';
  }

  getGitBranchValueFromConfiguration() {
    const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);

    if (token && token.configuration && token.configuration.gitbranch) {
      return token.configuration.gitbranch;
    }
    return '';
  }

  setBaseModuleHref(href) {
    this.setItem(CSTE_LS_KEYS.KEY_WR_MODULE_HREF, href);
  }

  getBaseModuleHref() {
    return this.getItem(CSTE_LS_KEYS.KEY_WR_MODULE_HREF);
  }

  setCurrentModule(module) {
    this.setItem(CSTE_LS_KEYS.KEY_WR_MODULE_CURRENT, module);
  }

  getCurrentModule() {
    return this.getItem(CSTE_LS_KEYS.KEY_WR_MODULE_CURRENT);
  }

  setModeSuperUser() {
    this.setItem(CSTE_LS_KEYS.KEY_WR_SUPERUSER, '1');
  }

  getModeSuperUser(): boolean {
    const val = this.getItem(CSTE_LS_KEYS.KEY_WR_SUPERUSER);

    if (!val) {
      return false;
    }
    return val === '1';
  }

  removeModeSuperUser() {
    this.removeItem(CSTE_LS_KEYS.KEY_WR_SUPERUSER);
  }

  setRequestsViewerParams(params: any): string {
    const uuid = `dashboard-list-request`;

    sessionStorage.setItem(uuid, JSON.stringify(params));

    return uuid;
  }

  getRequestsViewerParams(key: string) {
    const value = sessionStorage.getItem(key);

    return value && value.length ? JSON.parse(value) : undefined;
  }

  changeUserProfile(newIdProfile: number, newFeatures: any[]) {
    const token = this.getToken();

    token.user.ProfileId = newIdProfile;
    token.user.permissions.features = newFeatures;
    this.setToken(token);
  }

  getUser() {
    const token: TokenSession = this.getToken();

    if (!token || !token.user) {
      return undefined;
    }
    return token.user;
  }

  getUserProfile() {
    const user = this.getUser();

    if (user) {
      return user.ProfileId;
    }
    return null;
  }

  isUserAirInt(): boolean {
    const user = this.getUser();

    if (user && user.email && user.email.includes('airintservices.com')) {
      return true;
    }
    return false;
  }

  getUserSettings() {
    const token: TokenSession = this.getToken();

    if (!token || !token.user || !token.user.settings || !token.user.settings.length) {
      return undefined;
    }
    return JSON.parse(token.user.settings);
  }

  getUserDasboardLayout() {
    const userSettings = this.getUserSettings();

    if (!userSettings || !userSettings.dasboardLayout) {
      return AvailableKpis;
    }

    return userSettings.dasboardLayout;
  }

  setUserDasboardLayout(userLayout) {
    let userSettings = this.getUserSettings();

    if (!userSettings) {
      userSettings = {};
    }
    userSettings['dasboardLayout'] = userLayout;
    const token = this.getToken();

    token.user.settings = JSON.stringify(userSettings);
    this.setToken(token);
  }

  getUserPNFavorites() {
    const userSettings = this.getUserSettings();

    if (!userSettings || !userSettings.pn_favorites) {
      return [{id: 'AIS|TOP5', name: 'Top 5 requested parts', detail: []}];
    }

    if (!userSettings.pn_favorites.some(favorite => favorite.id === 'AIS|TOP5')) {
      userSettings.pn_favorites.unshift({
        id: 'AIS|TOP5',
        name: 'Top 5 requested parts',
        detail: [],
      });
    }

    return userSettings.pn_favorites;
  }

  setUserPNFavorites(favorites) {
    let userSettings = this.getUserSettings();

    if (!userSettings) {
      userSettings = {};
    }
    userSettings['pn_favorites'] = favorites;
    const token = this.getToken();

    token.user.settings = JSON.stringify(userSettings);
    this.setToken(token);
  }

  getMaintenanceFavorites() {
    const userSettings = this.getUserSettings();

    // if (!userSettings || !userSettings.pn_favorites) {
    //   return [{id: 'AIS|TOP5', name: 'Top 5 requested parts', detail: []}];
    // }

    return userSettings.maintenance_favorites || [];
  }

  setUserMaintenanceFavorites(favorites) {
    let userSettings = this.getUserSettings();

    if (!userSettings) {
      userSettings = {};
    }
    userSettings['maintenance_favorites'] = favorites;
    const token = this.getToken();

    token.user.settings = JSON.stringify(userSettings);
    this.setToken(token);
  }

  getPeriodeDashboard() {
    const userSettings = this.getUserSettings();

    if (!userSettings || !userSettings.dashboardPeriode) {
      return 'm-3';
    }

    return userSettings.dashboardPeriode;
  }

  async setPeriodeDashboard(periode: string) {
    let userSettings = this.getUserSettings();

    if (!userSettings) {
      userSettings = {};
    }
    userSettings['dashboardPeriode'] = periode;
    const token = this.getToken();

    token.user.settings = JSON.stringify(userSettings);
    this.setToken(token);

    const {id} = AISCacheService.getUser();

    await AISUserService.updateDashboardPeriode(id, periode);
  }

  getCriteriaMaintenance(withRfid = false): ICriteria {
    const userSettings = this.getUserSettings();

    if (!userSettings || !userSettings.maintenanceCriteria) {
      return {
        periode: 'm-1',
        groupBy: 'month',
        excludeRfid: true,
        source: 'MAINTENANCE',
      };
    }

    const criteria: ICriteria = userSettings.maintenanceCriteria;

    if (criteria) {
      if (!criteria.excludeRfid) {
        criteria.excludeRfid = true;
      }

      if (!criteria.source) {
        criteria.source = 'MAINTENANCE';
      }

      if (!withRfid) {
        criteria.source = 'MAINTENANCE';
      }

      // For performance reasons ( iberia) we are obliged to limit the period
      // if (['m-3', 'm-6', 'm-12', 'm-24', 'all'].includes(criteria.periode)) {
      //   criteria.periode = 'm-1';
      //   criteria.groupBy = 'month';
      // }
    }
    return (
      criteria || {
        periode: 'm-1',
        groupBy: 'month',
        excludeRfid: true,
        source: 'MAINTENANCE',
      }
    );
  }

  async setCriteriaMaintenance(criteria) {
    let userSettings = this.getUserSettings();

    if (!userSettings) {
      userSettings = {};
    }
    userSettings['maintenanceCriteria'] = criteria;
    const token = this.getToken();

    token.user.settings = JSON.stringify(userSettings);
    this.setToken(token);

    const {id} = AISCacheService.getUser();

    await AISUserService.updateMaintenanceCriteria(id, criteria);
  }

  getBuildTaskCheckFileKey(): string {
    let key = 'taskCheckFile_';
    const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);

    if (token && token.configuration && token.configuration.customer) {
      key += token.configuration.customer.oaci;
      key += `_${token.configuration.customer.id}`;
      key += `_${this.getEnvironment()}`;
    }
    return key;
  }

  setTaskCheckFile(data) {
    this.setItem('CHECK_FILE_OK', JSON.stringify(data));
  }

  getTaskCheckFile() {
    return this.getItemAsObject('CHECK_FILE_OK');
  }

  taskFileShowRecap(key): boolean {
    if (key === 'none') {
      return false;
    }

    const task = this.getTaskCheckFile();

    return !!(task && task.key === key);
  }

  removeTaskCheckFile() {
    this.removeItem('CHECK_FILE_OK');
  }

  getOACI(): string {
    const token = this.getItemAsObject(CSTE_LS_KEYS.KEY_TOKEN);

    if (!token) {
      return null;
    }
    return token?.configuration?.customer.oaci;
  }

  getJWTToken(): string {
    const fullToken = this.getToken();

    return fullToken?.token || null;
  }

  getJWTTokenDecryted() {
    const portalJWTToken: string = this.getJWTToken();

    const base64Url = portalJWTToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join(''),
    );

    return jsonPayload;
  }

  tokenIsExpired(): boolean {
    const val = this.getItem('tokenIsExpired');

    return val && val.length ? val === 'true' : false;
  }

  setTokenIsExpired() {
    this.setItem('tokenIsExpired', 'true');
  }

  removeTokenIsExpired() {
    this.removeItem('tokenIsExpired');
  }
}

export const AISCacheService = new AISCacheDataService();

// function genUuid() {
//   return [1, 2, 3, 4].map(() => (~~(Math.random() * 1e9)).toString(36)).join('-');
// }
