import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/pages/layout/SidebarLayout';
import BaseLayout from 'src/pages/layout/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Sidebar from 'src/pages/layout/SidebarLayout/Sidebar';
import SidebarMenu from 'src/pages/app-administrator/SidebarMenu';
import SidebarMenuWebReporting from 'src/pages/app-webreporting/SidebarMenu';
import SidebarMenuConfigurator from 'src/pages/app-configurator/SidebarMenu';
import { CSTE_LABELS } from 'src/commons/constants';


const Loader = Component =>
  function (props) {
    return (
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    );
  };

// Pages
//----------------------------------------------------------------
// Commons pages
//----------------------------------------------------------------
const Login = Loader(lazy(() => import('src/pages/login')));
const PageAppSelector = Loader(lazy(() => import('src/pages/selector')));
const PageStatus500 = Loader(lazy(() => import('src/pages/status/500')));
const Status404 = Loader(lazy(() => import('src/pages/status/404')));
const PageStatus403 = Loader(lazy(() => import('src/pages/status/403')));
const PageSSOAuthenticated = Loader(lazy(() => import('src/pages/login/sso-authenticated')));

//----------------------------------------------------------------
// Admin pages
//----------------------------------------------------------------
const PageAdminOverview = Loader(lazy(() => import('src/pages/app-administrator/overview')));
const PageAdminUsers = Loader(lazy(() => import('src/pages/app-administrator/users/List')));
const PageAdminUsersEdit = Loader(lazy(() => import('src/pages/app-administrator/users/Edit')));

const PageAdminAccountSeetings = Loader(
  lazy(() => import('src/pages/app-administrator/accounts-permissions')),
);
const PageEditProfile = Loader(
  lazy(() => import('src/pages/app-administrator/accounts-permissions/pageEditProfile')),
);

const PageDevices = Loader(lazy(() => import('src/pages/app-administrator/devices')));
const PageDocuments = Loader(lazy(() => import('src/pages/app-administrator/documents')));

const PageUploadDocuments = Loader(
  lazy(() => import('src/pages/app-administrator/documents/upload/uploadFormPage')),
);
const PageUploadList = Loader(
  lazy(() => import('src/pages/app-administrator/documents/upload/uploadListPage')),
);
const PageUploadDetail = Loader(
  lazy(() => import('src/pages/app-administrator/documents/upload/uploadDetailPage')),
);

const PageNotificationForm = Loader(
  lazy(() => import('src/pages/app-administrator/notifications/notificationFormPage')),
);
const PageNotificationList = Loader(
  lazy(() => import('src/pages/app-administrator/notifications/notificationListPage')),
);

const PageHistory = Loader(lazy(() => import('src/pages/app-administrator/history')));

const PageAircraftList = Loader(
  lazy(() => import('src/pages/app-webreporting/fleet/PageAircraftList')),
);

//----------------------------------------------------------------
// WEB REPORTING pages
//----------------------------------------------------------------
const PageDataExplorer = Loader(lazy(() => import('src/pages/app-webreporting/data-explorer')));
const PageWROverview = Loader(lazy(() => import('src/pages/app-webreporting/overview')));
const PageSearchPN = Loader(lazy(() => import('src/pages/app-webreporting/search-pn')));
const PageDataMaintenance = Loader(lazy(() => import('src/pages/app-webreporting/maintenance')));
const PageDataMaintenanceV2 = Loader(
  lazy(() => import('src/pages/app-webreporting/maintenanceV2')),
);
const PageRfidInventoryList = Loader(
  lazy(() => import('src/pages/app-webreporting/reports/rfid-inventory-list')),
);
const PageRequestsViewer = Loader(lazy(() => import('src/pages/app-webreporting/requestsViewer')));
const PageWeeklyReport = Loader(
  lazy(() => import('src/pages/app-webreporting/reports/weekly-report')),
);

//----------------------------------------------------------------
// CONFIGURATOR
//----------------------------------------------------------------
const PageConfiguratorOverview = Loader(lazy(() => import('src/pages/app-configurator/overview')));
const PageConfiguratorTables = Loader(lazy(() => import('src/pages/app-configurator/Tables')));
const ListAircraftPage = Loader(
  lazy(() => import('src/pages/app-configurator/Tables/listAircraftPage')),
);
const PageListKpis = Loader(lazy(() => import('src/pages/app-configurator/Tables/pageListKPIS')));
const PageNarrowWideBodies = Loader(
  lazy(() => import('src/pages/app-configurator/Tables/pageNarrowWideBodies')),
);
const PageGeneralSettings = Loader(
  lazy(() => import('src/pages/app-configurator/general-settings')),
);
const PageCustomerInterop = Loader(
  lazy(() => import('src/pages/app-configurator/customer-interop')),
);
const PageEditCustomerInterop = Loader(
  lazy(() => import('src/pages/app-configurator/customer-interop/pageEditCustomerInterop')),
);
const PageDispatcherEnvs = Loader(
  lazy(() => import('src/pages/app-configurator/dispatcher/pageDispatcherEnvs')),
);
const PageDispatcherEnvsVars = Loader(
  lazy(() => import('src/pages/app-configurator/dispatcher/pageDispatcherEnvsVars')),
);
const PageManageTheme = Loader(lazy(() => import('src/pages/app-configurator/theme/manageTheme')));
const PageConfigureSSO = Loader(lazy(() => import('src/pages/app-configurator/sso/configureSSO')));
const PageManageCacheAndFiles = Loader(
  lazy(() => import('src/pages/app-configurator/filesAndCache/')),
);

const PageAdminDatabase = Loader(lazy(() => import('src/pages/app-configurator/database')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: 'app-selector',
        element: <PageAppSelector />,
      },
      {
        path: 'sso-authenticated',
        element: <PageSSOAuthenticated />,
      },
      {
        path: '/',
        element: <Login />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: 'status',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="500" replace />,
      },
      {
        path: '500',
        element: <PageStatus500 />, // <Login />, //
      },
      {
        path: '403',
        element: <PageStatus403 />, // <Login />, //
      },
    ],
  },
  {
    path: 'administrator',
    element: (
      <SidebarLayout>
        <Sidebar app_title={CSTE_LABELS.ADMINISTRATION.APPTITLE}>
          <SidebarMenu />
        </Sidebar>
      </SidebarLayout>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="overview" replace />,
      },
      {
        path: 'overview',
        element: <PageAdminOverview />,
      },
      {
        path: 'users',
        element: <PageAdminUsers />,
      },
      {
        path: 'users/edit',
        element: <PageAdminUsersEdit />,
      },
      {
        path: 'users/edit/:userId',
        element: <PageAdminUsersEdit />,
      },
      {
        path: 'accounts-permissions',
        element: <PageAdminAccountSeetings />,
      },
      {
        path: 'accounts-permissions/edit',
        element: <PageEditProfile />,
      },
      {
        path: 'accounts-permissions/edit/:profileId',
        element: <PageEditProfile />,
      },
      {
        path: 'data-explorer',
        element: <PageDataExplorer />,
      },

      {
        path: 'devices',
        element: <PageDevices />,
      },
      {
        path: 'documents',
        element: <PageDocuments />,
      },
      {
        path: 'uploadcmm/submit',
        element: <PageUploadDocuments />,
      },
      {
        path: 'uploadcmm/detail/:id',
        element: <PageUploadDetail />,
      },
      {
        path: 'uploadcmm',
        element: <PageUploadList />,
      },
      {
        path: 'history',
        element: <PageHistory />,
      },
      {
        path: 'notification/list',
        element: <PageNotificationList />,
      },
      {
        path: 'notification/create',
        element: <PageNotificationForm />,
      },
    ],
  },
  {
    path: 'webreporting',
    element: (
      <SidebarLayout>
        <Sidebar app_title={CSTE_LABELS.WEBREPORTING.APPTITLE}>
          <SidebarMenuWebReporting />
        </Sidebar>
      </SidebarLayout>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="overview" replace />,
      },
      {
        path: 'overview',
        element: <PageWROverview />,
      },
      {
        path: 'reports/weekly-report',
        element: <PageWeeklyReport />,
      },
      {
        path: 'data-explorer',
        element: <PageDataExplorer />,
      },
      {
        path: 'search-pn',
        element: <PageSearchPN />,
      },
      {
        path: 'maintenanceV1',
        element: <PageDataMaintenance />,
      },
      {
        path: 'maintenance',
        element: <PageDataMaintenanceV2 />,
      },
      {
        path: 'reports/rfid-inventories',
        element: <PageRfidInventoryList />,
      },
      {
        path: 'notification/list',
        element: <PageNotificationList />,
      },
      {
        path: 'notification/create',
        element: <PageNotificationForm />,
      },
      {
        path: 'dashboard/requestsViewer/:uuid',
        element: <PageRequestsViewer />,
      },
      {
        path: 'fleet/aircraftlist',
        element: <PageAircraftList />,
      },
    ],
  },
  {
    path: 'configurator',
    element: (
      <SidebarLayout>
        <Sidebar app_title={CSTE_LABELS.AIRINTADMIN.APPTITLE}>
          <SidebarMenuConfigurator />
        </Sidebar>
      </SidebarLayout>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="overview" replace />,
      },
      {
        path: 'overview',
        element: <PageConfiguratorOverview />,
      },
      {
        path: 'data-explorer',
        element: <PageDataExplorer />,
      },
      {
        path: 'notification/list',
        element: <PageNotificationList />,
      },
      {
        path: 'notification/create',
        element: <PageNotificationForm />,
      },
      {
        path: 'tables/list',
        element: <PageConfiguratorTables />,
      },
      {
        path: 'tables/aircraft',
        element: <ListAircraftPage />,
      },
      {
        path: 'tables/kpis',
        element: <PageListKpis />,
      },
      {
        path: 'tables/narrow-wide-bodies',
        element: <PageNarrowWideBodies />,
      },
      {
        path: 'general-settings',
        element: <PageGeneralSettings />,
      },
      {
        path: 'customer-interop',
        element: <PageCustomerInterop />,
      },
      {
        path: 'customer-interop/edit',
        element: <PageEditCustomerInterop />,
      },
      {
        path: 'customer-interop/edit/:customerInteropId',
        element: <PageEditCustomerInterop />,
      },
      {
        path: 'dispatcher/envs',
        element: <PageDispatcherEnvs />,
      },
      {
        path: 'dispatcher/environmentvariables',
        element: <PageDispatcherEnvsVars />,
      },
      {
        path: 'theme/settings',
        element: <PageManageTheme />,
      },
      {
        path: 'sso/settings',
        element: <PageConfigureSSO />,
      },
      {
        path: 'database',
        element: <PageAdminDatabase />,
      },
      {
        path: 'accounts-permissions',
        element: <PageAdminAccountSeetings />,
      },
      {
        path: 'accounts-permissions/edit',
        element: <PageEditProfile />,
      },
      {
        path: 'accounts-permissions/edit/:profileId',
        element: <PageEditProfile />,
      },
      {
        path: 'cache-and-files',
        element: <PageManageCacheAndFiles />,
      },
    ],
  },
];



export default routes;


